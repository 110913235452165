/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "",
          redirect: "/pages/login",
        },
        //===========================================================================
        {
          path: "/home/manimage",
          name: "manageimage",
          component: () =>
            import("./views/imageManagement/imagemanagement.vue"),
          meta: {
            rule: "Editor",
          },
        },
        //===========================================================================
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("./views/Dashboard/Dashboard.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/home",
          name: "home",
          // component: () => import("./views/Ticket.vue"),
          component: () => import("./views/Dashboard/Dashboard.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/ticket",
          name: "tickets",
          component: () => import("./views/Ticket.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/ticket/:id",
          name: "form",
          props: true,
          component: () => import("./views/Chat.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/user",
          name: "user",
          props: true,
          component: () => import("./views/UserPart/UserPage/User.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/transaction/:id",
          name: "transaction",
          component: () => import("./views/TransList.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/test",
          name: "test page",
          component: () => import("./views/test.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/transaction/:id",
          name: "transaction",
          component: () => import("./views/TransList.vue"),
          meta: {
            rule: "Editor",
          },
        },
        // {
        //   path: "/userlist",
        //   name: "userList",
        //   component: () => import("./views/UserList.vue"),
        //   meta: {
        //     rule: "Editor"
        //   }
        // },
        {
          path: "/healthcheck",
          name: "Health Check data",
          // component: () => import("./views/healthcheck/main.vue"),
          component: () => import("./views/healthcheck/main.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/userlist",
          name: "userList",
          component: () => import("./views/UserPart/UserList.vue"),
          meta: {
            rule: "Editor",
          },
        },
        //======================================================================
        {
          path: "/incomeexport",
          name: "incomeexport",
          component: () => import("./views/invoices/monthlyIncomeExport.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/paymentincome",
          name: "paymentincome",
          component: () => import("./views/invoices/PaymentIncome"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/userservices/:id",
          name: "userservices Haio cloud",
          component: () =>
            import("./views/UserPart/UserServices/UserServices.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/userservices/vpc/:id",
          name: "userservices vpc",
          component: () =>
            import("./views/UserPart/UserServices/serviceComponents/VPC.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/userservices/viracloud/:id",
          name: "userservices viracloud",
          component: () =>
            import(
              "./views/UserPart/UserServices/serviceComponents/Viracloud.vue"
            ),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/userservices/haiocloud/:id",
          name: "userservices haiocloud",
          component: () =>
            import(
              "./views/UserPart/UserServices/serviceComponents/Haiocloud.vue"
            ),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/userservices/host/:id",
          name: "userservices host",
          component: () =>
            import("./views/UserPart/UserServices/serviceComponents/Host.vue"),
          meta: {
            rule: "Editor",
          },
        },
        //======================================================================
        {
          path: "/haiocloudlist",
          name: "haioCLoudList",
          component: () => import("./views/haiocloud/HaioCloud.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/salestodo/",
          name: "salestodo",
          component: () => import("./views/salesTodo/index.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/salestodo/show/:id",
          name: "salestodo",
          component: () => import("./views/salesTodo/show.vue"),
          meta: {
            rule: "Editor",
          },
        },
        // VIRA CLOUD----------------------------------------------------------------
        {
          path: "/viracloudlist",
          name: "ViraCLoudList",
          component: () => import("./views/viracloud/ViraCloud.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/auctionserversettings",
          name: "ViraCLoudList",
          component: () => import("./views/viracloud/AuctionServerSettings.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/viracloudmigrate",
          name: "Vira Cloud migration",
          component: () => import("./views/VPC/VpcMigration/vpcmigration.vue"),
          meta: {
            rule: "Editor",
          },
        },
        // VIRA CLOUD----------------------------------------------------------------
        {
          path: "/hostlist",
          name: "host list",
          component: () => import("./views/HostsList/hostlist.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/postpaidusers",
          name: "PostPaidUsersList",
          component: () => import("./views/InvocePart/postpaid.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/domain",
          name: "domain",
          component: () => import("./views/email/Domain.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/usersSalecooperation",
          name: "usersSalecooperation",
          component: () => import("./views/usersSalecooperation/index.vue"),
          meta: {
            rule: "Editor",
          },
        },

        {
          path: "/importantusers",
          name: "importantusers",
          component: () => import("./views/importantusers/index.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/domain/:id",
          name: "account",
          component: () => import("./views/email/Email.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/faktor",
          name: "account-invoce",
          component: () => import("./views/InvocePart/CreateFaktor.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/VPCL",
          name: "VPC list",
          component: () => import("./views/VPC/VPClist.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/invoicelist",
          name: "list of invoices",
          component: () => import("./views/invoices/invoicelist.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/paymentgateways",
          name: "list of payment gateways",
          component: () => import("./views/invoices/paymentGateways.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/notification",
          name: "notification part",
          component: () => import("./views/notificationsPart/notifpage.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/nodereport",
          name: "Node reports",
          component: () => import("./views/reports/NodeReports.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/faktorytask",
          name: "faktory tasks",
          component: () => import("./views/Faktory(MQ)/faktorytask.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/services",
          name: "demanded services",
          component: () => import("./views/DemandedServices/DemandList.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/service/:id",
          name: "demanded services",
          component: () => import("./views/DemandedServices/AnswerDemand.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/proxmox/vm/resource/report",
          name: "userservices host",
          component: () => import("./views/proxmox/main.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "logs/traffic",
          name: "show logs ",
          component: () => import("./views/Logs/TrafficLog.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "logs/ipfilter",
          name: "ipfilter",
          component: () => import("./views/Logs/IpFilter.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "logs/events",
          name: "events",
          component: () => import("./views/Logs/Events.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "logs/traffic-realtime",
          name: "show Real time traffic ",
          component: () => import("./views/Logs/TrafficLogRealtime.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "logs/ip",
          name: "show logs ",
          component: () => import("./views/Logs/IPLog.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "abuselist",
          name: "abuse list ",
          component: () => import("./views/haiocloud/AbuseList.vue"),
          meta: {
            rule: "Editor",
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/printinvoicelist/:id",
          name: "print invoice",
          component: () => import("./views/invoices/printinvoice.vue"),
          meta: {
            rule: "Editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Login.vue"),
          meta: {
            rule: "Editor",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/login",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.path === "/" || from.path === "/") {
    // localStorage.clear()
    window.clearInterval();
  }
  next();
});

export default router;
