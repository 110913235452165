import axios from '@/axios'
import store from '../../../../store/store.js'
// eslint-disable-next-line no-unused-vars
import router from '@/router'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then((res) => {
              
              const access_token = res.data.params.data.access_token
              const refresh_token  = res.data.params.data.refresh_token
              localStorage.setItem('accessToken', access_token)
              localStorage.setItem('refreshToken', refresh_token)
              store.commit('auth/SET_BEARER', access_token)
              store.dispatch('auth/fetchUserData')
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      } else if (response && (response.status === 503 || response.status === 400)) {
        // localStorage.clear()
        // store.dispatch('auth/backToLogin')
      }
      return Promise.reject(error)
    })
  },
  // headerSet () {
  //   const accessToken = localStorage.getItem('accessToken')
  //   if (accessToken) {

  //     axios.interceptors.request.use(function (config) {
  //       config.headers.Authorization =  `Bearer ${accessToken}`
  //       config.headers['Content-Type'] =  'application/json'
    
  //       return config
  //     })
  //   } else {
  //     delete axios.defaults.headers.common['Authorization']
  //   }
  // },
  
  login (email, pwd) {
    return axios.post('/user/login', {
      email,
      password: pwd
    })
  },
  registerUser (name, email, mobile, pwd) {
    return axios.post('/user/register', {
      name,
      email,
      mobile,
      password: pwd
    })
  },
  logout () {
    return axios.get('/user/logout')
  },
  // eslint-disable-next-line no-unused-vars
  getInfo (header) {
    
    // const config = {
    //   method: 'get',
    //   url: '/user/info',
    //   headers:{ 'Authorization': `Bearer ${header}`, 'Content-Type': 'application/json' }
    // }
    return axios.get('/user/info')
          
  },
  refreshToken () {
    const data = {refresh_token: localStorage.getItem('refreshToken')}
    return axios.post('/user/refresh/token', data)
  }
  
}
