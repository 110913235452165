// axios
import axios from 'axios'

const baseURL = 'https://api.haio.ir/v1/'
// const accessToken = localStorage.getItem('accessToken')

export default axios.create({
  baseURL,
  //  headers : {
  //   'Authorization' : `Bearer ${accessToken}`
  // }
  // You can add your headers here
})
