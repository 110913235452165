/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'


import router from '@/router'


export default {
  // JWT
  loginJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
        
          // If there's user data in response
          if (response.data.status) {

            localStorage.setItem('accessToken', response.data.params.data.access_token)
            localStorage.setItem('refreshToken', response.data.params.data.refresh_token)

            commit('SET_BEARER',  response.data.params.data.access_token)
    
            resolve(response)
          } else {
            reject({message: 'Wrong Email Or Password'})
          }
          
       
        }).catch(err => { reject(err) })
      
    })
  },

  //register 

  fetchUserData ({commit}, payload) {
    jwt.getInfo(payload).then((response) => {
      const userData = response.data.params    
      commit('UPDATE_USER_INFO', userData, {root: true})
    }).catch()
  },

  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) }).catch(() => {
        router.push({path : '/pages/login'})
     
      })
    })
    // location.reload()
  }

}