import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'Admin'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
if (userInfo && userInfo.userRole) initialRole = userInfo.userRole

export default new AclCreate({
  initial  : initialRole,
  notfound : '/pages/error-404',
  router,
  acceptLocalRules : true,
  globalRules: {
    Admin     :  new AclRule('Admin').generate(),
    Editor    :  new AclRule('Editor').or('Admin').generate()
  
  }
})
