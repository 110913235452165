//global methods
// import methods from "./globalMethods";
// Vue.prototype.$methods = methods
import Vue from "vue";
import App from "./App.vue";
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing"
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
// React Query Package
const test = async function (data) {
  console.log(data);
};

Vue.prototype.$test = test;

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

//bootstrap vue
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.component('date-picker-fa', VuePersianDatetimePicker);
// ACL
import acl from "./acl/acl.js";

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;

// Filters
import "./filters/filters.js";

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// SENTRY configuration
// Sentry.init({
//   Vue,
//   dsn: "http://6e15bb1f92784080a1fb67921613a40c@192.168.40.1:9000/4",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "adm.haio.ir", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


//  Circular prograssbar
import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);


// This mehods purpose is only to ease debugging
Vue.prototype.$log = function (param) {
  console.log(param || "print line");
};

Vue.config.productionTip = false;

new Vue({
  // methods,
  router,
  store,
  acl,
  render: (h) => h(App),
}).$mount("#app");
