/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {title: 'Home',   url: '/home',      icon: 'HomeIcon', is_bookmarked: false}
    ]
  }
}
/* eslint-enable */
